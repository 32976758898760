<script setup lang="ts">
import type { StatProps } from '#core/components/text/UiStat.vue'

const props = withDefaults(defineProps<{
  data: StatProps[]
  variant?: 'card' | 'cards' | 'inline'
  wrap?: boolean
}>(), {
  variant: 'card',
  wrap: false,
})

const container = computed(() => {
  return props.variant === 'card' ? 'px-5 py-4 sm:px-5 sm:py-4 rounded-lg border border-neutral-600 bg-white' : ''
})
</script>

<template>
  <div data-ui="UiStats" class="flex justify-around" :class="[container, { 'flex-wrap': wrap }]">
    <UiStat v-for="item in data" :key="item.title" v-bind="item" :variant="variant === 'cards' ? 'card' : 'inline'" align="center" />
  </div>
</template>
