<script lang="ts">
import type { IconClass } from '#core/types'
import type { Value, ValueFormat } from '#core/utils/format-value'
import type { Component } from 'vue'
import { isVNode } from 'vue'

type MaybeComponent = Component | (() => Component)

export interface StatProps {
  // global
  variant?: 'card' | 'inline'
  align?: 'left' | 'center' | 'right'
  invalid?: boolean

  // top
  icon?: IconClass
  title?: string
  tooltip?: string

  // middle
  value?: Value
  format?: string | ValueFormat

  // bottom
  info?: string
  meta?: string | number | MaybeComponent
  action?: IconClass | MaybeComponent

  // components
  left?: MaybeComponent
  right?: MaybeComponent
}
</script>

<script setup lang="ts">
/**
 * UiStat
 *
 * Kitchen-sink stats component
 */
const props = withDefaults(defineProps<StatProps>(), {
  variant: 'inline',
  align: 'left',
  value: undefined,
})

defineEmits(['action'])

const slots = useSlots()

const alignClasses = {
  left: 'justify-start text-left',
  center: 'justify-center text-center',
  right: 'justify-end text-right',
}

const hasInfo = computed(() => {
  return !!(slots.info || props.info)
})

const ui = computed(() => {
  return {
    container: props.variant === 'card'
      ? 'rounded-lg p-5 border border-neutral-600 bg-white'
      : '',
    align: alignClasses[props.align],
    gap: hasInfo.value
      ? 'space-y-5'
      : 'space-y-2',
  }
})
</script>

<template>
  <div data-ui="UiStat" :class="[ui.container, ui.align, ui.gap]" class="text-gray-600 flex-grow grow-1">
    <!-- label + tooltip -->
    <slot name="label">
      <div class="flex flex-row items-center gap-1" :class="ui.align">
        <UiIcon v-if="icon" size="md" :name="icon" />
        <UiLabel class="text-xs" :text="title" :tooltip="tooltip" />
      </div>
    </slot>

    <!-- middle and bottom row -->
    <div class="flex flex-row gap-2">
      <!-- left slot -->
      <component :is="left" v-if="isVNode(left)" />
      <div v-else-if="$slots.left" class="shrink-0">
        <slot name="left" />
      </div>

      <!-- value + right slot -->
      <div class="flex flex-col w-full gap-1">
        <!-- default slot -->
        <slot>
          <div class="flex flex-row items-center gap-2" :class="ui.align">
            <slot name="value">
              <UiFormat :size="hasInfo ? 'lg' : 'md'" :value="value" :format="format" class="text-primary-900" />
            </slot>
            <component :is="right" v-if="isVNode(right)" />
            <slot v-else-if="$slots.right" name="right" />
          </div>
        </slot>

        <!-- bottom + edit -->
        <div v-if="hasInfo" class="flex flex-row justify-between items-center w-full h-4 gap-2 text-xs" :class="align === 'right' ? '!flex-row-reverse' : ''">
          <!-- info -->
          <div class="flex items-center gap-1 text-xs">
            <slot v-if="$slots.info || info" name="info">
              {{ info }}<template v-if="info && meta">
                :
              </template>
              <component :is="meta" v-if="isVNode(meta)" />
              <span v-else-if="meta" class="font-semibold">{{ meta }}</span>
            </slot>
          </div>

          <!-- edit -->
          <slot v-if="$slots.action || action" name="action">
            <component :is="action" v-if="isVNode(action)" />
            <UiButton size="xs" :icon="action" @click="$emit('action')" />
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
